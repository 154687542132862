import React, { useState } from "react";
import styled from "styled-components";
import { color, distance, font, fontSize } from "../styles";

const Footer = styled.footer`
  background-color: ${color.darkest};
  margin-top: auto;
`;

const StyledForm = styled.form`
  display: flex;
`;

const TextInput = styled.input`
  width: 70%;
  height: 3.5rem;
  border-radius: ${distance.smallest} 0 0 ${distance.smallest};
  border: 0.25rem solid ${color.accent2};
  outline: none;
  box-sizing: border-box;
  padding-left: ${distance.medium};
  font-size: ${fontSize.medium};
  font-family: ${font.normal};
`;

const SubmitInput = styled.input`
  width: 30%;
  background-color: ${color.accent1};
  color: ${color.bright};
  font-size: ${fontSize.large};
  border: none;
  outline: none;
  border-radius: 0 ${distance.smallest} ${distance.smallest} 0;
  :active {
    background-color: ${color.accent2};
  }
`;

interface Props {
  submitInput: (textInput: string) => void;
}

const FooterComp = ({ submitInput }: Props) => {
  const [textInput, setTextInput] = useState("");

  const submitAndClear = (e: any) => {
    e.preventDefault();
    submitInput(textInput);
    setTextInput("");
  };

  return (
    <Footer>
      <StyledForm onSubmit={(e) => submitAndClear(e)}>
        <TextInput
          type="text"
          name="itemInput"
          id=""
          maxLength={32}
          placeholder="Add item..."
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
        />
        <SubmitInput type="submit" value="Add" />
      </StyledForm>
    </Footer>
  );
};

export default FooterComp;
