import React from "react";
import styled from "styled-components";
import { color } from "../styles";

interface Props {
  label: string;
}

const StyledH2 = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.darkest};
  color: ${color.accent1};
  font-weight: 500;
  margin: 0;
  height: 3rem;
`;

const Heading2 = ({ label }: Props) => {
  return <StyledH2>{label}</StyledH2>;
};

export default Heading2;
