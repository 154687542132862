import React from "react";
import { color } from "../styles";

const XIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 22L22 10"
        stroke={color.accent1}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M10 10L22 22"
        stroke={color.accent1}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default XIcon;
