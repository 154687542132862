import React from "react";
import styled from "styled-components";
import { color, font, fontSize } from "../styles";

interface Props {
  content: JSX.Element;
  action: any;
  width: string;
  bgColor: string;
}

interface ButtonProps {
  width: string;
  bgColor: string;
}

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  color: ${color.dark};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "transparent"};
  height: 3rem;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: 3rem;
  border: none;
  font-family: ${font.normal};
  font-size: ${fontSize.medium};
  text-align: left;
`;

const ButtonComp = ({ content, action, width, bgColor }: Props) => {
  return (
    <StyledButton onClick={action} width={width} bgColor={bgColor}>
      {content}
    </StyledButton>
  );
};

export default ButtonComp;
