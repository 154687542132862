import { useEffect, useState } from "react";
import styled from "styled-components";
import FooterComp from "./components/FooterComp";
import HeaderComp from "./components/HeaderComp";
import MainComp from "./components/MainComp";
import { color } from "./styles";

const BASE_URL = "https://grocery-list-v2-api.stefansjoman.se/";
// const BASE_URL = "http://localhost:1234/";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${color.darkest};
`;

export interface Item {
  name: string;
  picked: boolean;
}

function App() {
  const [allItems, setAllItems] = useState<Item[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    await fetch(`${BASE_URL}items`)
      .then((response) => response.json())
      .then((data) => {
        setAllItems(data);
      })
      .catch((error) => console.log(error));
  };

  const submitInput = async (textInput: string) => {
    if (validationAndNoDuplicates(textInput)) {
      let tempList = [...allItems];
      tempList.push({ name: textInput, picked: false });
      postList(tempList);
    }
  };

  const postList = async (tempList: Item[]) => {
    await fetch(`${BASE_URL}create`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ list: tempList }),
    });
    getItems();
  };

  const deleteOne = async (itemName: string) => {
    const tempList = allItems.filter((allItem) => allItem.name !== itemName);
    postList(tempList);
  };

  const deleteAll = async () => {
    postList([]);
  };

  const validationAndNoDuplicates = (textInput: string) => {
    return (
      textInput !== "" &&
      textInput.length <= 32 &&
      !allItems.find((oneItem) => oneItem.name === textInput)
    );
  };

  return (
    <AppWrapper className="App">
      <HeaderComp {...{ deleteAll }} />
      <MainComp
        {...{
          allItems,
          postList,
          deleteOne,
        }}
      />
      <FooterComp submitInput={submitInput} />
    </AppWrapper>
  );
}

export default App;
