import React from "react";

interface Props {
  iconColor?: string;
  backgroundFill?: string;
}

const Trash = ({ iconColor = "black", backgroundFill = "none" }: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={backgroundFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_701_2)">
        <rect
          x="8"
          y="9"
          width="16"
          height="18"
          rx="2"
          stroke={iconColor}
          strokeWidth="2"
        />
        <rect
          x="7"
          y="5"
          width="18"
          height="4"
          rx="2"
          stroke={iconColor}
          strokeWidth="2"
        />
        <line
          x1="12"
          y1="24"
          x2="12"
          y2="12"
          stroke={iconColor}
          strokeWidth="2"
        />
        <line
          x1="16"
          y1="24"
          x2="16"
          y2="12"
          stroke={iconColor}
          strokeWidth="2"
        />
        <line
          x1="20"
          y1="24"
          x2="20"
          y2="12"
          stroke={iconColor}
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_701_2">
          <rect
            width="20"
            height="24"
            stroke={iconColor}
            transform="translate(6 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Trash;
