import React from "react";
import styled from "styled-components";
import Trash from "../icons/Trash";
import { color, distance, font, fontSize } from "../styles";
import ButtonComp from "./ButtonComp";

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${color.darkest};
`;

const Heading1 = styled.h1`
  font-size: ${fontSize.header};
  font-family: ${font.header};
  margin: 0 ${distance.small};
  text-align: center;
  font-weight: 500;
`;

const Version = styled.span`
  color: ${color.accent1};
`;

const DeleteAllBtn = styled(ButtonComp)`
  background: transparent;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  border-radius: ${distance.small};
  overflow: hidden;
`;

interface Props {
  deleteAll: () => void;
}

const HeaderComp = ({ deleteAll }: Props) => {
  return (
    <Header>
      <Heading1>
        GroceryList<Version>V2</Version>
      </Heading1>
      <DeleteAllBtn
        content={<Trash iconColor={color.accent1} />}
        action={() => deleteAll()}
        width={"auto"}
        bgColor={"transparent"}
      />
    </Header>
  );
};

export default HeaderComp;
