import React from "react";
import styled from "styled-components";
import XIcon from "../icons/XIcon";
import { border, color, distance } from "../styles";
import ButtonComp from "./ButtonComp";
import { Item } from "../App";

const Listing = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${color.darkest};
  border: ${border.listItem};
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${distance.small};
  overflow: hidden;
  margin: ${distance.small} 0;
  border-left: ${distance.smallest} solid ${color.accent2};
`;

const EmptyList = styled.div`
  padding: 0 ${distance.small};
  background-color: ${color.brightest};
  border-radius: ${distance.small};
  border-left: ${distance.smallest} solid ${color.accent2};
  height: 0.25rem;
  margin: ${distance.small} 0;
`;

const TextButton = styled(ButtonComp)`
  background-color: ${color.brightest};
  text-align: left;
`;

interface Props {
  items: Item[];
  moveItem: (item: string) => void;
  deleteOne?: (item: string) => void | undefined;
}

const ListComp = ({ items, moveItem, deleteOne }: Props) => {
  const listedItems = items?.map((item) => (
    <ListItem key={item.name}>
      <TextButton
        content={<span>{item.name}</span>}
        action={() => moveItem(item.name)}
        width={"100%"}
        bgColor={color.brightest}
      />
      {deleteOne && (
        <ButtonComp
          content={<XIcon />}
          action={() => deleteOne(item.name)}
          width={"auto"}
          bgColor={color.brightest}
        />
      )}
    </ListItem>
  ));
  return (
    <Listing>{items && items.length > 0 ? listedItems : <EmptyList />}</Listing>
  );
};

export default ListComp;
