export const color = {
  brightest: "#f0f0f0",
  bright: "#e5e5e5",
  accent1: "#7711ff",
  accent2: "#ff6622",
  dark: "#404040",
  darkest: "#202020",
};

export const distance = {
  smallest: "0.25rem",
  small: "0.5rem",
  medium: "1rem",
  large: "2rem",
  largest: "4rem",
};

export const fontSize = {
  smallest: "0.8rem",
  small: "0.9rem",
  medium: "1rem",
  large: "1.2rem",
  largest: "1.5rem",
  header: "2rem",
};

export const font = {
  header: "Helvetica",
  normal: "Helvetica",
};

export const border = {
  listItem: `0.13rem solid ${color.darkest}`,
};
