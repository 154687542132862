import React from "react";
import styled from "styled-components";
import ListComp from "./ListComp";
import Heading2 from "./Heading2";
import { color, distance, font, fontSize } from "../styles";
import { Item } from "../App";

const Main = styled.main`
  background-color: ${color.bright};
  font-family: ${font.normal};
  font-size: ${fontSize.medium};
  margin: 0 ${distance.small};
`;

interface Props {
  allItems: Item[];
  postList: (tempList: Item[]) => void;
  deleteOne: (item: string) => void;
}

const MainComp = ({ allItems, postList, deleteOne }: Props) => {
  const itemsToBuy: Item[] = [];
  const pickedItems: Item[] = [];

  allItems.forEach((item) => {
    if (!item.picked) {
      return itemsToBuy.push(item);
    }
    return pickedItems.push(item);
  });

  const moveItem = (itemName: string) => {
    const tempList = [...allItems];
    const indexOfItem = tempList.findIndex((item) => item.name === itemName);
    tempList[indexOfItem].picked = !tempList[indexOfItem].picked;
    // setAllItems(tempList);
    postList(tempList);
  };

  return (
    <Main>
      <Heading2 label={"Items to buy"} />
      <ListComp items={itemsToBuy} {...{ moveItem }} />
      <Heading2 label={"Picked items"} />
      <ListComp items={pickedItems} {...{ moveItem, deleteOne }} />
    </Main>
  );
};

export default MainComp;
